import { UPDATE_STAFFING_STATE } from '../types/staffingTypes';

const initialState = {
  isOpenAddToJobDialog: false,
  isOpenDealInsightsDialog: false,
  isOpenProjectInsightsDialog: false,
  isOpenAddJobToDealProjectDialog: false,
  isOpenCreateDealDialog: false,
  isOpenAddProjectToDealDialog: false,
  isOpenCreateContractorDialog: false,
  isOpenCreateBlockedTimeDialog: false,
  isOpenCreateProjectDialog: false,
  isOpenAddJobToProjectDialog: false,
  isOpenProjectRoleActionDialog: false,
  isOpenProjectRoleActionFeedbackDialog: false,
  isOpenStaffingChecklistModal: false,
  projectRoleActionDialogType: '', // accept || reject
  contractorToUpdateId: null,
  projectToUpdateId: null,
  dealProjectToUpdateId: null,
  dealToUpdateId: null,
  dealId: null,
  staffingChecklistToUpdateId: null,
  dealInsightId: null,
  projectInsightId: null,
  /**
   * Deal list filters
   */
  dealsCompany: '',
  dealsPrice: '',
  dealsStatus: '',
  /**
   * Project list filters
   */
  projectsStatus: '',
};

export default function staffingReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_STAFFING_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
