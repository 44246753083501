import React from 'react';
import { useDispatch } from 'react-redux';

// library components
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

//cr components
import AddBlockedTimeForm from 'components/Forms/Staffing/AddBlockedTimeForm';

//actions & selectors
import { openCreateBlockedTimeDialog } from 'redux/actions/staffingActions';
import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';

const useStyles = makeStyles(theme => ({
  paperWidthSm: {
    maxWidth: 1000,
  },
  contentRoot: {
    padding: theme.spacing(5),
  },
  dialogHeader: {
    position: 'relative',
    maxWidth: 500,
    width: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogHeaderRoot: {
    padding: '24px 8px',
  },
  dialogHeaderTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  cancelButton: {
    position: 'absolute',
    right: theme.spacing(1),
  },
}));

const CreateBlockedTimeDialog = () => {
  const { isOpenCreateBlockedTimeDialog } = useStaffingSelector();

  const dispatch = useDispatch();
  const classes = useStyles();

  const closeDialog = () => {
    dispatch(openCreateBlockedTimeDialog(false));
  };

  return (
    <div>
      <Dialog
        open={isOpenCreateBlockedTimeDialog}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        classes={{
          paperWidthSm: classes.paperWidthSm,
        }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          disableTypography
          className={classes.dialogHeader}
          classes={{ root: classes.dialogHeaderRoot }}
        >
          <Typography className={classes.dialogHeaderTitle}>Blocked Time</Typography>
          <IconButton className={classes.cancelButton} aria-label="close" onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.contentRoot}>
          <AddBlockedTimeForm closeDialog={closeDialog} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateBlockedTimeDialog;
