import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import { map } from 'lodash';
import moment from 'moment'; // Make sure to import moment

import { makeStyles } from '@material-ui/styles';
import { useConfirm } from 'material-ui-confirm';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Typography from '@material-ui/core/Typography';
import PageContent from 'components/PageContent/PageContent';
import PageContentHeader from 'components/PageContent/PageContentHeader';
import PageContentBody from 'components/PageContent/PageContentBody';
import Button from 'components/CustomButtons/Button.js';

//staffing components
import CreateBlockedTimeDialog from './CreateBlockedTimeDialog';

//actions & selectors
import { deleteStaffBlockedTime, openCreateBlockedTimeDialog } from 'redux/actions/staffingActions';
import { selectCurrentContractorBlockedTimes } from 'redux/selectors/staffing/getContractor';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
  pageHeader: {
    paddingBottom: '0px !important',
  },
  gridItem: {
    padding: '0 10px !important',
  },
  createContractorButton: {
    height: 36,
  },
  tabWrapper: {
    marginLeft: 15,
  },
  filterWrapper: {
    marginLeft: 5,
    marginRight: 15,
  },
});

// New function to format date and time
const formatDateTime = (date, time) => {
  return moment(`${date} ${time}`).format('ddd, MMM D, YYYY [at] h:mm A');
};

const MyAvailabilityList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const getBlockedTimes = selectCurrentContractorBlockedTimes();
  const { blockedTimes, isLoaded } = useSelector(state => getBlockedTimes(state));
  console.log('blockedTimes MyAvailabilityList:', blockedTimes);

  const firestoreState = useSelector(state => state.firestore);
  const entireState = useSelector(state => state);

  const openCreateBlockedTime = () => {
    dispatch(openCreateBlockedTimeDialog(true));
  };

  const deleteBlockedTime = id => {
    confirm({
      description: `Are you sure you want to delete this blocked time?`,
    }).then(() => {
      dispatch(deleteStaffBlockedTime(id));
    });
  };

  // Hand for debugging...
  // useEffect(() => {
  //   console.log('Entire Redux state:', entireState);
  //   console.log('Firestore state:', firestoreState);
  //   console.log('getBlockedTimes result:', { blockedTimes, isLoaded });
  //   console.log('selectStaffBlockedTimes function:', selectStaffBlockedTimes.toString());
  // }, [entireState, firestoreState, blockedTimes, isLoaded]);

  if (!isLoaded) {
    console.log('blockedTimes not loaded.', blockedTimes);
    return null;
  }

  return (
    <div>
      <GridContainer direction="row" justify="center" alignItems="center">
        <GridItem xs={12}>
          <PageContent>
            <PageContentHeader className={classes.pageHeader}>
              <Typography variant="h5" className="page-content-title">
                My Availability
              </Typography>
              <div className="right-col">
                <Button
                  color="primary"
                  onClick={openCreateBlockedTime}
                  className={classes.createContractorButton}
                  size="sm"
                >
                  Add Blocked Time
                </Button>
              </div>
            </PageContentHeader>
            <PageContentBody>
              {isEmpty(blockedTimes) ? (
                <Typography style={{ fontSize: 18 }} className="w-100 text-center mt-4">
                  No Blocked Times
                </Typography>
              ) : (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Label</TableCell>
                        <TableCell>Start</TableCell>
                        <TableCell>End</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {map(blockedTimes, (blockedTime, idx) => (
                        <TableRow key={`blocked-time-${idx}`}>
                          {blockedTime && blockedTime.id ? (
                            <>
                              <TableCell>{blockedTime.displayName}</TableCell>
                              <TableCell>
                                {formatDateTime(blockedTime.startDate, blockedTime.startTime)}
                              </TableCell>
                              <TableCell>
                                {formatDateTime(blockedTime.endDate, blockedTime.endTime)}
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    dispatch(openCreateBlockedTimeDialog(true, blockedTime.id));
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    deleteBlockedTime(blockedTime.id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </>
                          ) : (
                            <TableCell colSpan={4}>No valid blocked time data</TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </PageContentBody>
          </PageContent>
        </GridItem>
      </GridContainer>

      <CreateBlockedTimeDialog />
    </div>
  );
};

export default MyAvailabilityList;
