import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

// library components
import { Formik, Form, FieldArray, Field } from 'formik';
import { Button, Grid, InputLabel, IconButton, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

//validation library
import * as Yup from 'yup';
import { isEqual, isSameDay, parse, isAfter, isBefore, addDays } from 'date-fns';

// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import TextInput from 'components/CustomFormComponents/TextInput';

//actions & selectors
import { createStaffBlockedTime, updateStaffBlockedTime } from 'redux/actions/staffingActions';
import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';
import { selectSingleStaffBlockedTime } from 'redux/selectors/staffing/getContractor';

import { useFirestore } from 'react-redux-firebase';
import MaterialDate from 'components/CustomFormComponents/MaterialDate';
import MaterialTime from 'components/CustomFormComponents/MaterialTime';

const useStyles = makeStyles(theme => ({
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  },
  saveButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '200px',
  },
  errorMessage: {
    marginTop: theme.spacing(1),
    fontSize: '0.875rem',
    textAlign: 'right',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 6,
    marginBottom: 6,
  },
  removeButton: {
    marginLeft: 5,
    borderRadius: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  addButton: {
    width: 80,
    marginTop: 5,
    background: '#DCE5EF',
    borderRadius: 5,
    color: '#093B75',
    fontSize: 14,
    '&:hover': {
      background: '#DCE5EF',
      opacity: 0.9,
    },
  },
  cancelButton: {
    width: 105,
    height: 40,
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
    },
  },
  saveButton: {
    width: 105,
    height: 40,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },
  helperText: {
    fontSize: 12,
    display: 'inline',
    color: theme.palette.text.hint,
    fontStyle: 'italic',
  },
}));

const FieldText = React.memo(({ name }) => (
  <Field
    name={`${name}`}
    component={({ field }) => (
      <TextInput name={name} type="text" className="flex-grow-1" required />
    )}
  />
));

const AddBlockedTimeForm = ({ closeDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const db = useFirestore();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { blockedTimeToUpdateId } = useStaffingSelector();
  const isEditing = !!blockedTimeToUpdateId;
  const getBlockedTime = selectSingleStaffBlockedTime();

  //Get the Contractor Info from the Store
  const blockedTime = useSelector(state => getBlockedTime(state, blockedTimeToUpdateId));
  const user = useSelector(state => state.firebase.profile);

  const formInitialValues = {
    displayName: blockedTime ? blockedTime.displayName : '',
    startDate: blockedTime ? blockedTime.startDate : '',
    startTime: blockedTime ? blockedTime.startTime : '00:00:00',
    endDate: blockedTime ? blockedTime.endDate : '',
    endTime: blockedTime ? blockedTime.endTime : '23:59:59',
  };

  const formSchema = Yup.object().shape({
    displayName: Yup.string().required('Required'),
    startDate: Yup.date().required('Required'),
    startTime: Yup.string().required('Required'),
    endDate: Yup.date()
      .required('Required')
      .min(Yup.ref('startDate'), 'End date must be on or after the start date'),
    endTime: Yup.string()
      .required('Required')
      .test(
        'end-time-after-start-time',
        'End time must be after start time when dates are the same',
        function (endTime) {
          const { startDate, endDate, startTime } = this.parent;

          console.log('Validation input:', { startDate, endDate, startTime, endTime });

          if (isSameDay(startDate, endDate)) {
            console.log('Dates are the same, proceeding with time comparison');

            const createDateTime = (date, timeString) => {
              const [hours, minutes] = timeString.split(':').map(Number);
              return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);
            };

            const startDateTime = createDateTime(startDate, startTime);
            const endDateTime = createDateTime(endDate, endTime);

            console.log('Created date times:', { startDateTime, endDateTime });

            // Check if end time is after start time or before the start of the next day
            const isValid = isAfter(endDateTime, startDateTime);

            console.log('Validation result:', isValid);
            return isValid;
          }

          console.log('Dates are not the same, validation passed');
          return true;
        },
      ),
  });

  // Helper function to convert time string to minutes
  const convertTimeToMinutes = timeString => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const handleFormSubmit = async (values, { setErrors }) => {
    setIsSubmitting(true);

    const successCallback = () => {
      setIsSubmitting(false);
      closeDialog();
    };

    if (isEditing) {
      console.log('updateStaffBlockedTime: blockedTimeToUpdateId', blockedTimeToUpdateId);
      dispatch(updateStaffBlockedTime(blockedTimeToUpdateId, { ...values }, true, successCallback));
    } else {
      console.log('createStaffBlockedTime: values', values);
      dispatch(createStaffBlockedTime({ ...values, userId: user.uid }, true, successCallback));
    }
  };

  return (
    <Formik
      validationSchema={formSchema}
      initialValues={formInitialValues}
      onSubmit={handleFormSubmit}
    >
      {({
        submitForm,
        resetForm,
        values,
        setFieldValue,
        errors,
        setErrors,
        submitCount,
        touched,
      }) => (
        <Form style={{ paddingBottom: 20 }}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={12}>
              <TextInput name="displayName" type="text" label="Name" className="mb-4" required />

              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <MaterialDate
                    name="startDate"
                    type="date"
                    label="Start Date"
                    className="mb-4"
                    required
                  />
                </Grid>

                <Grid item xs={6}>
                  <MaterialTime
                    name="startTime"
                    type="time"
                    label="Start Time"
                    className="mb-4"
                    required
                  />
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <MaterialDate
                    name="endDate"
                    type="date"
                    label="End Date"
                    className="mb-4"
                    required
                  />
                </Grid>

                <Grid item xs={6}>
                  <MaterialTime
                    name="endTime"
                    type="time"
                    label="End Time"
                    className="mb-4"
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div className={classes.dialogActions}>
            <Button
              className={classes.cancelButton}
              disabled={isSubmitting}
              onClick={() => {
                closeDialog();
                resetForm();
              }}
            >
              CANCEL
            </Button>
            <div className={classes.saveButtonContainer}>
              <Button className={classes.saveButton} disabled={isSubmitting} onClick={submitForm}>
                SAVE
                {isSubmitting && <ButtonSpinner />}
              </Button>
              {submitCount > 0 &&
                Object.keys(errors).length > 0 &&
                Object.keys(touched).length > 0 && (
                  <Typography color="error" className={classes.errorMessage}>
                    See errors above.
                  </Typography>
                )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddBlockedTimeForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};

export default AddBlockedTimeForm;
