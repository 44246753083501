import React from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { merge, map, startCase, values, flatMap } from 'lodash';

// core components
import { makeStyles } from '@material-ui/core';

import PickerSelect from 'components/Staffing/PickerSelect/PickerSelect';

import { setDealsFilterPayload } from 'redux/actions/staffingActions';
import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';
import { PROJECT_STATUS_STATES, PROJECT_ERROR_STATUS_STATES } from 'constants/staffing';

import './projectsFilter.scss';

// useStyles
const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 25,
    gap: '15px',
  },
  formControl: {
    margin: theme.spacing(1),
    marginRight: -5,
    flexGrow: 1,
  },
  input: {
    width: 143,
    backgroundColor: '#EEEEEE',
  },
  inputLabel: {
    paddingLeft: 10,
    zIndex: 2,
  },
  select: {
    '& .MuiSelect-select': {
      paddingLeft: theme.spacing(1), // Adds left padding to the selected value
    },
  },
  pickerSelect: {
    minWidth: 200, // Increase this value as needed
    '& .MuiInputBase-root': {
      width: '100%',
    },
  },
}));

const ProjectsFilter = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { projectsStatus } = useStaffingSelector();

  const handleSelect = (value, filterParam) => {
    dispatch(
      setDealsFilterPayload({
        [filterParam]: value,
      }),
    );
  };

  const PROJECT_STATUS_OPTIONS = flatMap(
    [PROJECT_STATUS_STATES, PROJECT_ERROR_STATUS_STATES],
    values,
  ).map(value => ({
    value,
    name: startCase(value),
  }));

  return (
    <div className={cx(classes.main)}>
      <PickerSelect
        label="Status"
        classNames={`deals-filter-select-input ${classes.select} ${classes.pickerSelect}`}
        options={PROJECT_STATUS_OPTIONS}
        onChange={value => {
          handleSelect(value, 'projectsStatus');
        }}
      />
    </div>
  );
};

export default ProjectsFilter;
