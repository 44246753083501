import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { filter } from 'lodash';
import { useLocation, Link } from 'react-router-dom';
import cx from 'classnames';

// core components
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import PageContent from 'components/PageContent/PageContent';
import PageContentHeader from 'components/PageContent/PageContentHeader';
import PageContentBody from 'components/PageContent/PageContentBody';

//cr components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

//staffing components
import TabToggler from 'components/Staffing/TabToggler';
import OpenJobCard from 'components/Staffing/ContractorDashboard/OpenJobCard';

//actions & selectors
import OpenProjectRoleActionDialog from 'components/Dialogs/Staffing/OpenProjectRoleActionDialog';
import { selectSignedInContractor } from 'redux/selectors/staffing/getContractor';

const queryString = require('query-string');

// useStyles
const useStyles = makeStyles(theme => ({
  gridItem: {
    padding: '0 10px !important',
  },
  tab: {
    minWidth: 80,
    fontSize: 12,
    boxShadow: 'inset 0px -2px 0px #666666',
    marginBlock: 3,
  },
  footerLink: {
    color: theme.palette.satisfied.main,
  },
  allJobsButtonContainer: {
    top: 22,
    '@media (max-width: 576px)': {
      top: 0,
    },
  },
}));

const OpenJobsList = () => {
  const classes = useStyles();
  const location = useLocation();
  const urlParams = queryString.parse(location.search);

  //Active Tab - Active or InActive
  const [activeTab, setActiveTab] = useState(0);
  const getContractor = selectSignedInContractor();
  const contractor = useSelector(state => getContractor(state));

  if (!contractor) {
    return null;
  }

  const { displayName, contractorJobs } = contractor;

  const hasFilters = urlParams.jobId || urlParams.projectId;
  const jobFilter = urlParams.jobId;
  const projectFilter = urlParams.projectId;

  const displayedJobs = hasFilters
    ? contractorJobs
    : filter(contractorJobs, { isActive: activeTab === 0 });
  //console.log("displayedJobs", displayedJobs)

  const filterJob = job => {
    return (
      hasFilters &&
      ((jobFilter && jobFilter !== job.jobId) || (projectFilter && projectFilter !== job.projectId))
    );
  };

  return (
    <div>
      <GridContainer direction="row" justify="center" alignItems="center">
        <GridItem xs={12}>
          <PageContent>
            <PageContentHeader className={cx(!hasFilters && 'pb-0')}>
              <Typography variant="h5" className="page-content-title">
                {displayName}
              </Typography>
              {!hasFilters ? (
                <TabToggler
                  labels={['Current Jobs', 'Past Jobs']}
                  activeTab={activeTab}
                  onChangeTab={setActiveTab}
                />
              ) : (
                <div className={cx(classes.allJobsButtonContainer, 'left-col')}>
                  <Button
                    variant="text"
                    className="d-flex align-items-center justify-content-left"
                    color="primary"
                    component={Link}
                    to="/admin/contractor"
                  >
                    <ArrowBackIosIcon fontSize="small" />
                    <p className="m-0 text-uppercase">Show All Jobs</p>
                  </Button>
                </div>
              )}
            </PageContentHeader>
            <PageContentBody>
              <GridContainer className="w-100 m-0">
                {displayedJobs.map((job, idx) => (
                  <>
                    {!filterJob(job) && (
                      <GridItem
                        key={idx.toString()}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className="mb-4"
                      >
                        <OpenJobCard job={job} contractorId={contractor.id} />
                      </GridItem>
                    )}
                  </>
                ))}
              </GridContainer>
            </PageContentBody>
          </PageContent>
        </GridItem>
        <GridItem>
          <Box display="flex" justifyContent="center">
            <Typography
              color="textSecondary"
              style={{ maxWidth: 500, fontWeight: '300', fontSize: 15 }}
              className="text-center"
            >
              Have questions or concerns, contact VC Ops{' '}
              <a className={classes.footerLink} href="mailto:ops@voltagecontrol.com">
                ops@voltagecontrol.com
              </a>
            </Typography>
          </Box>
        </GridItem>
      </GridContainer>

      <OpenProjectRoleActionDialog />
    </div>
  );
};

export default OpenJobsList;
